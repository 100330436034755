import { resetPasswordConstants } from './constants';
import { combineReducers } from 'redux';

const initialState = {
  isLoadInner: false,
  isLoad: false,
  message: null,
  loadErr: null,
  data: {},
  success_message: '',
};

function resetPassword(state = initialState, action) {
  switch (action.type) {
    // Loading
    case resetPasswordConstants.FORGOT_PASSWORD_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: '',
        success_message: '',
      };
    case resetPasswordConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
        success_message: '',
      };
    case resetPasswordConstants.FLUSH:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: null,
        success_message: '',
      };
    // Default
    default:
      return state;
  }
}

function verifyToken(state = initialState, action) {
  switch (action.type) {
    // Loading
    case resetPasswordConstants.VERIFY_TOKEN_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: '',
        success_message: '',
      };
    case resetPasswordConstants.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.VERIFY_TOKEN_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.FLUSH:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: null,
        success_message: '',
      };
    // Default
    default:
      return state;
  }
}

function verifyOtp(state = initialState, action) {
  switch (action.type) {
    // Loading
    case resetPasswordConstants.VERIFY_OTP_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: '',
        success_message: '',
      };
    case resetPasswordConstants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.VERIFY_OTP_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        success_message: '',
      };
    case resetPasswordConstants.FLUSH:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: null,
        success_message: '',
      };
    // Default
    default:
      return state;
  }
}

function setNewPassword(state = initialState, action) {
  switch (action.type) {
    // Loading
    case resetPasswordConstants.RESET_PASSWORD_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: '',
        success_message: '',
      };
    case resetPasswordConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: action.message,
        success_message: '',
      };
    case resetPasswordConstants.FLUSH:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: null,
        success_message: '',
      };
    // Default
    default:
      return state;
  }
}
const resetPasswordReducer = combineReducers({
  resetPassword,
  verifyToken,
  setNewPassword,
  verifyOtp,
});

export default resetPasswordReducer;
