export const tripConstants = {
  LOAD: 'tripConstants/LOAD',
  LOAD_SUCCESS: 'tripConstants/LOAD_SUCCESS',
  LOAD_FAIL: 'tripConstants/LOAD_FAIL',
  FLUSH: 'tripConstants/FLUSH',
  ALL_TRIP_ACCOUNTS: 'tripConstants/ALL_TRIP_ACCOUNTS',
  ALL_TRIP_LEG_ID: 'tripConstants/ALL_TRIP_LEG_ID',
  ALL_CORPORATE_ACCOUNT: 'tripConstants/ALL_CORPORATE_ACCOUNT',
  SAVE_TRIP_ACCOUNTS: 'tripConstants/SAVE_TRIP_ACCOUNTS',
  DELETE_TRIP_ACCOUNTS: 'tripConstants/DELETE_TRIP_ACCOUNTS',
  COMPLETED_LOAD: 'tripConstants/COMPLETED_LOAD',
  COMPLETED_LOAD_SUCCESS: 'tripConstants/COMPLETED_LOAD_SUCCESS',
  COMPLETED_LOAD_FAIL: 'tripConstants/COMPLETED_LOAD_FAIL',
  ARCHIVED_LOAD: 'tripConstants/ARCHIVED_LOAD',
  ARCHIVED_LOAD_SUCCESS: 'tripConstants/ARCHIVED_LOAD_SUCCESS',
  ARCHIVED_LOAD_FAIL: 'tripConstants/ARCHIVED_LOAD_FAIL',
  ALL_ARCHIVED_TRIPS: 'tripConstants/ALL_ARCHIVED_TRIPS',
  COMPLETED_FLUSH: 'tripConstants/COMPLETED_FLUSH',
  ALL_COMPLETED_TRIPS: 'tripConstants/ALL_COMPLETED_TRIPS',
  GET_QUOTE: 'tripConstants/GET_QUOTE',
  GET_QUOTE_SUCCESS: 'tripConstants/GET_QUOTE_SUCCESS',
  GET_QUOTE_FAIL: 'tripConstants/GET_QUOTE_FAIL',
  SAVE_QUOTATION: 'tripConstants/SAVE_QUOTATION',
  FLUSH_QUOTATION: 'tripConstants/FLUSH_QUOTATION',
  ADD_FEEDBACK_LOAD: 'tripConstants/ADD_FEEDBACK_LOAD',
  ADD_FEEDBACK_SUCCESS: 'tripConstants/ADD_FEEDBACK_SUCCESS',
  ADD_FEEDBACK_FAIL: 'tripConstants/ADD_FEEDBACK_FAIL',
  FEEDBACK_MESSAGE_FLUSH: 'tripConstants/FEEDBACK_MESSAGE_FLUSH',
  GET_FEEDBACK_LOAD: 'tripConstants/GET_FEEDBACK_LOAD',
  GET_FEEDBACK_SUCCESS: 'tripConstants/GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_FAIL: 'tripConstants/GET_FEEDBACK_FAIL',
  GET_FEEDBACK_FLUSH: 'tripConstants/GET_FEEDBACK_FLUSH',
  GET_REASONS_LOAD: 'tripConstants/GET_REASONS_LOAD',
  GET_REASONS_SUCCESS: 'tripConstants/GET_REASONS_SUCCESS',
  GET_REASONS_FAIL: 'tripConstants/GET_REASONS_FAIL',
  SAVE_LEG: 'tripConstants/SAVE_LEG',
  SAVE_FILTERS: 'tripConstants/SAVE_FILTERS',
  GET_FILTERS: 'tripConstants/GET_FILTERS',
  SAVE_FILTER_COMPLETED: 'tripConstants/SAVE_FILTER_COMPLETED',
  GET_FILTER_COMPLETED: 'tripConstants/GET_FILTER_COMPLETED',
  SAVE_FILTERS_ARCHIVED: 'tripConstants/SAVE_FILTERS_ARCHIVED',
  GET_FILTERS_ARCHIVED: 'tripConstants/GET_FILTERS_ARCHIVED',
  SAVE_FILTERS_QUOTATION: 'tripConstants/SAVE_FILTERS_QUOTATION',
  GET_FILTERS_QUOTATION: 'tripConstants/GET_FILTERS_QUOTATION',
  GET_TRIPLOG_LOAD: 'tripConstants/GET_TRIPLOG_LOAD',
  GET_TRIPLOG_SUCCESS: 'tripConstants/GET_TRIPLOG_SUCCESS',
  GET_TRIPLOG_FAIL: 'tripConstants/GET_TRIPLOG_FAIL',
  TRIP_LOGS_FLUSH: 'tripConstants/TRIP_LOGS_FLUSH',
  SAVE_CHARGES_LOAD: 'tripConstants/SAVE_CHARGES_LOAD',
  SAVE_CHARGES_SUCCESS: 'tripConstants/SAVE_CHARGES_SUCCESS',
  SAVE_CHARGES_FAIL: 'tripConstants/SAVE_CHARGES_FAIL',
  GET_CHARGES_LOAD: 'tripConstants/GET_CHARGES_LOAD',
  GET_CHARGES_SUCCESS: 'tripConstants/GET_CHARGES_SUCCESS',
  GET_CHARGES_FAIL: 'tripConstants/GET_CHARGES_FAIL',
  GET_CHARGES_LIST: 'tripConstants/GET_CHARGES_LIST',
  DELETE_CHARGES_LOAD: 'tripConstants/DELETE_CHARGES_LOAD',
  DELETE_CHARGES_SUCCESS: 'tripConstants/DELETE_CHARGES_SUCCESS',
  DELETE_CHARGES_FAIL: 'tripConstants/DELETE_CHARGES_FAIL',
  DELETE_CHARGES_FLUSH: 'tripConstants/DELETE_CHARGES_FLUSH',
  UPDATE_QUOTATION_LOAD: 'tripConstants/UPDATE_QUOTATION_LOAD',
  UPDATE_QUOTATION_SUCCESS: 'tripConstants/UPDATE_QUOTATION_SUCCESS',
  UPDATE_QUOTATION_FAIL: 'tripConstants/UPDATE_QUOTATION_FAIL',
};
export const tripExportConstants = {
  CompletedTrip: 'completed-trip',
};
