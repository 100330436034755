export const paymentConstants = {
  LOAD: 'payment/LOAD',
  LOAD_SUCCESS: 'payment/LOAD_SUCCESS',
  LOAD_FAIL: 'payment/LOAD_FAIL',
  FLUSH: 'payment/FLUSH',

  PAYMENT_LIST_SUCCESS: 'payment/PAYMENT_LIST_SUCCESS',

  CORPORATE_ACCOUNTS_LOAD: 'payment/CORPORATE_ACCOUNTS_LOAD',
  CORPORATE_ACCOUNTS_LOAD_SUCCESS: 'payment/CORPORATE_ACCOUNTS_LOAD_SUCCESS',
  CORPORATE_ACCOUNTS_LOAD_FAIL: 'payment/CORPORATE_ACCOUNTS_LOAD_FAIL',
  CORPORATE_ACCOUNTS_SUCCESS: 'payment/CORPORATE_ACCOUNTS_SUCCESS',

  FILTER_INVOICES_LOAD: 'payment/FILTER_INVOICES_LOAD',
  FILTER_INVOICES_LOAD_SUCCESS: 'payment/FILTER_INVOICES_LOAD_SUCCESS',
  FILTER_INVOICES_LOAD_FAIL: 'payment/FILTER_INVOICES_LOAD_FAIL',
  FILTER_INVOICES_SUCCESS: 'payment/FILTER_INVOICES_SUCCESS',

  UNAPPLY_INVOICES_LOAD: 'payment/UNAPPLY_INVOICES_LOAD',
  UNAPPLY_INVOICES_LOAD_SUCCESS: 'payment/UNAPPLY_INVOICES_LOAD_SUCCESS',
  UNAPPLY_INVOICES_LOAD_FAIL: 'payment/UNAPPLY_INVOICES_LOAD_FAIL',
  UNAPPLY_INVOICES_SUCCESS: 'payment/UNAPPLY_INVOICES_SUCCESS',

  AUDIT_DETAILS_LOAD: 'payment/AUDIT_DETAILS_LOAD',
  AUDIT_DETAILS_LOAD_SUCCESS: 'payment/AUDIT_DETAILS_LOAD_SUCCESS',
  AUDIT_DETAILS_LOAD_FAIL: 'payment/AUDIT_DETAILS_LOAD_FAIL',
  AUDIT_DETAILS_SUCCESS: 'payment/AUDIT_DETAILS_SUCCESS',
  PAYMENT_ID_DETAILS_SUCCESS: 'payment/PAYMENT_ID_DETAILS_SUCCESS',
  PAYMENT_ID_LOAD: 'payment/PAYMENT_ID_LOAD',
  PAYMENT_ID_LOAD_SUCCESS: 'payment/PAYMENT_ID_LOAD_SUCCESS',
  PAYMENT_ID_LOAD_FAIL: 'payment/PAYMENT_ID_LOAD_FAIL',
};
