import { corporateConstants } from './constants';

const initialState = {
  isLoadInner: false,
  isLoad: false,
  isLoadUpdate: false,
  message: null,
  loadErr: null,
  all_corporate_acounts: [],
  saved_item: null,
  charges: [],
  charges_load: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case corporateConstants.LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
      };
    case corporateConstants.LOAD_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
      };
    case corporateConstants.LOAD_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
      };

    case corporateConstants.GET_INVOICE_LOAD:
      return {
        ...state,
        charges_load: true,
        loadErr: null,
      };
    case corporateConstants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        charges_load: false,
        loadErr: null,
        charges: action.data,
      };
    case corporateConstants.GET_INVOICE_FAIL:
      return {
        ...state,
        charges_load: false,
        loadErr: action.error,
        charges: [],
      };

    case corporateConstants.GET_VERSIONS_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
      };
    case corporateConstants.GET_VERSIONS_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        data: action.data,
      };
    case corporateConstants.GET_VERSIONS_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
      };
    case corporateConstants.INVOICE_FLUSH:
      return {
        ...state,
        charges_load: false,
        loadErr: '',
        charges: [],
      };

    case corporateConstants.UPDATE_VERSION_LOAD:
      return {
        ...state,
        isLoadUpdate: true,
        loadErr: null,
      };
    case corporateConstants.UPDATE_VERSION_SUCCESS:
      return {
        ...state,
        isLoadUpdate: false,
        loadErr: null,
      };
    case corporateConstants.UPDATE_VERSION_FAIL:
      return {
        ...state,
        isLoadUpdate: false,
        loadErr: action.error,
        message: null,
      };

    case corporateConstants.FLUSH:
      return {
        ...state,
        message: null,
      };
    case corporateConstants.FLUSH_ERROR:
      return {
        ...state,
        loadErr: null,
      };
    case corporateConstants.ALL_CORPORATE_ACCOUNTS:
      return {
        ...state,
        all_corporate_acounts: action.all_accounts,
        isLoadInner: true,
      };
    case corporateConstants.SAVE_CORPORATE_ACCOUNTS:
      return {
        ...state,
        saved_item: action.save_item,
      };
    case corporateConstants.UPDATE_PRICE_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
      };
    case corporateConstants.UPDATE_PRICE_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
      };
    case corporateConstants.UPDATE_PRICE_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
      };
    case corporateConstants.FLUSH_MESSAGE:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: null,
      };
    // Default
    default:
      return state;
  }
}
