export const accountTripConstants = {
  LOAD: 'tripConstants/LOAD',
  LOAD_SUCCESS: 'tripConstants/LOAD_SUCCESS',
  LOAD_FAIL: 'tripConstants/LOAD_FAIL',
  FLUSH: 'tripConstants/FLUSH',
  ALL_TRIP_ACCOUNTS: 'tripConstants/ALL_TRIP_ACCOUNTS',
  ALL_TRIP_LEG_ID: 'tripConstants/ALL_TRIP_LEG_ID',
  ALL_CORPORATE_ACCOUNT: 'tripConstants/ALL_CORPORATE_ACCOUNT',
  SAVE_TRIP_ACCOUNTS: 'tripConstants/SAVE_TRIP_ACCOUNTS',
  DELETE_TRIP_ACCOUNTS: 'tripConstants/DELETE_TRIP_ACCOUNTS',
  ALL_COMPLETED_TRIPS: 'tripConstants/ALL_COMPLETED_TRIPS',
  GET_QUOTE: 'tripConstants/GET_QUOTE',
  GET_QUOTE_SUCCESS: 'tripConstants/GET_QUOTE_SUCCESS',
  GET_QUOTE_FAIL: 'tripConstants/GET_QUOTE_FAIL',
  SAVE_QUOTATION: 'tripConstants/SAVE_QUOTATION',
  FLUSH_QUOTATION: 'tripConstants/FLUSH_QUOTATION',
  SAVE_LEG: 'tripConstants/SAVE_LEG',
  SAVE_FILTERS: 'tripConstants/SAVE_FILTERS',
  GET_FILTERS: 'tripConstants/GET_FILTERS',
  SAVE_CHARGES_LOAD: 'tripConstants/SAVE_CHARGES_LOAD',
  SAVE_CHARGES_SUCCESS: 'tripConstants/SAVE_CHARGES_SUCCESS',
  SAVE_CHARGES_FAIL: 'tripConstants/SAVE_CHARGES_FAIL',
  GET_CHARGES_LOAD: 'tripConstants/GET_CHARGES_LOAD',
  GET_CHARGES_SUCCESS: 'tripConstants/GET_CHARGES_SUCCESS',
  GET_CHARGES_FAIL: 'tripConstants/GET_CHARGES_FAIL',
  SAVE_FILTERS_QUOTATION: 'tripConstants/SAVE_FILTERS_QUOTATION',
  GET_FILTERS_QUOTATION: 'tripConstants/GET_FILTERS_QUOTATION',
  UPDATE_QUOTATION_LOAD: 'tripConstants/UPDATE_QUOTATION_LOAD',
  UPDATE_QUOTATION_SUCCESS: 'tripConstants/UPDATE_QUOTATION_SUCCESS',
  UPDATE_QUOTATION_FAIL: 'tripConstants/UPDATE_QUOTATION_FAIL',
  GET_QUOTATION_LOAD: 'tripConstants/UPDATE_QUOTATION_LOAD',
  GET_QUOTATION_SUCCESS: 'tripConstants/UPDATE_QUOTATION_SUCCESS',
  GET_QUOTATION_FAIL: 'tripConstants/UPDATE_QUOTATION_FAIL',
};
