import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setFilters } from './action';

export const useFilterState = () => {
  const { filters } = useSelector((state) => {
    return state.reactTable;
  });
  const dispatch = useDispatch();

  const setFilterState = (payload) => {
    dispatch(setFilters(payload));
  };
  const resetFilter = () => {
    dispatch(resetFilters());
  };
  return {
    setFilterState,
    filters,
    resetFilter,
  };
};
