export const transportConstants = {
  LOAD: 'transportConstants/LOAD',
  LOAD_SUCCESS: 'transportConstants/LOAD_SUCCESS',
  LOAD_FAIL: 'transportConstants/LOAD_FAIL',
  FLUSH: 'transportConstants/FLUSH',
  TRIP_LOAD: 'transportConstants/TRIP_LOAD',
  TRIP_LOAD_SUCCESS: 'transportConstants/TRIP_LOAD_SUCCESS',
  TRIP_LOAD_FAIL: 'transportConstants/TRIP_LOAD_FAIL',
  TRIP_ACCOUNTS: 'transportConstants/TRIP_ACCOUNTS',
  SAVE_TRANSPORT: 'transportConstants/SAVE_TRANSPORT',
  UNIT_ACCOUNTS: 'transportConstants/UNIT_ACCOUNTS',
  MERGE_ACCOUNTS: 'transportConstants/MERGE_ACCOUNTS',
  TRANSPORTS_ACCOUNTS: 'transportConstants/TRANSPORTS_ACCOUNTS',
  FINISHED_TRANSPORTS_ACCOUNTS:
    'transportConstants/FINISHED_TRANSPORTS_ACCOUNTS',
  FLUSH_UNITS: 'transportConstants/FLUSH_UNITS',
  SAVE_FILTERS: 'transportConstants/SAVE_FILTERS',
  GET_FILTERS: 'transportConstants/GET_FILTERS',
  SAVE_DATE_LOAD: 'transportConstants/SAVE_DATE_LOAD',
  SAVE_DATE_LOAD_SUCCESS: 'transportConstants/SAVE_DATE_LOAD_SUCCESS',
  SAVE_DATE_LOAD_FAIL: 'transportConstants/SAVE_DATE_LOAD_FAIL',
};
