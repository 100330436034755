export const vehicleConstants = {
	LOAD: 'vehicle/LOAD',
	LOAD_SUCCESS: 'vehicle/LOAD_SUCCESS',
	LOAD_FAIL: 'vehicle/LOAD_FAIL',
	FLUSH: 'vehicle/FLUSH',
	SAVE_VEHICEL: 'vehicle/SAVE_VEHICEL',
	ROLES: 'vehicle/ROLES',
	ALL_VEHICEL: 'vehicle/ALL_VEHICEL',
	CAPABILITY_ROLES: 'driver/CAPABILITY_ROLES',
	SAVED_CAPABILITY_ROLES: 'driver/SAVED_CAPABILITY_ROLES',
}
