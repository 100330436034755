export const unitConstants = {
  LOAD: "unitConstants/LOAD",
  LOAD_SUCCESS: "unitConstants/LOAD_SUCCESS",
  LOAD_FAIL: "unitConstants/LOAD_FAIL",
  FLUSH: "unitConstants/FLUSH",
  ALL_UNITS: "unitConstants/ALL_UNITS",
  DELETE_UNIT: "unitConstants/DELETE_UNIT",
  PAST_UNITS: "unitConstants/PAST_UNITS",
  SAVE_UNIT: "unitConstants/SAVE_UNIT",
  ALL_UNIT_DRIVERS: "unitConstants/ALL_UNIT_DRIVERS",
  ALL_VEHICLE_CODE: "unitConstants/ALL_VEHICLE_CODE",
  RESET_MESSAGE: "unitConstants/RESET_MESSAGE",
};
