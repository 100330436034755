import { reactTableConstant } from './constants';

const initialState = {
  filters: {},
};

export default function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case reactTableConstant.SET_FILTER:
      let localState = state.filters || {};
      localState[payload.key] = payload.value;
      return {
        ...state,
        filters: localState,
      };
    case reactTableConstant.RESET_FILTER:
      return {
        ...state,
        filters: {},
      };
    // Default
    default:
      return state;
  }
}
