import { accountTripConstants } from './constants';
const initialState = {
  isLoadInner: false,
  isLoad: false,
  isLoadSave: false,
  isLoadUpdate: false,
  isLoadGet: false,
  message: null,
  loadErr: null,
  all_trip_acounts: [],
  all_corporates: [],
  saved_item: null,
  completed: [],
  save_quotation: {},
  errData: {},
  save_leg: {},
  total_trips: 0,
  total_trips_completed: 0,
  total_charges: 0,
  saveFilters: {},
  saveFilterQuotation: {},
  getFilterQuotation: {},
  getFilters: {},
  savedQuotation: [],
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case accountTripConstants.LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
        isLoadInner: false,
      };
    case accountTripConstants.LOAD_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        isLoadInner: true,
        message: action.message,
      };
    case accountTripConstants.LOAD_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
        errData: action.errData,
      };

    case accountTripConstants.GET_QUOTATION_LOAD:
      return {
        ...state,
        isLoadGet: true,
        loadErr: null,
      };
    case accountTripConstants.GET_QUOTATION_SUCCESS:
      return {
        ...state,
        isLoadGet: false,
        loadErr: null,
        message: action.message,
      };
    case accountTripConstants.GET_QUOTATION_FAIL:
      return {
        ...state,
        isLoadGet: false,
        loadErr: action.error,
        message: null,
        errData: action.errData,
      };

    case accountTripConstants.FLUSH:
      return {
        ...state,
        message: null,
        errData: {},
        loadErr: '',
      };
    case accountTripConstants.ALL_TRIP_ACCOUNTS:
      return {
        ...state,
        all_trip_acounts: action.all_trips,
        total_trips: action.total_trips,
        isLoadInner: true,
      };
    case accountTripConstants.ALL_CORPORATE_ACCOUNT:
      return {
        ...state,
        all_corporates: action.all_corporates,
      };
    case accountTripConstants.SAVE_TRIP_ACCOUNTS:
      return {
        ...state,
        saved_item: action.saved_item,
      };
    case accountTripConstants.SAVE_LEG:
      return {
        ...state,
        save_leg: action.save_leg,
      };
    case accountTripConstants.ALL_COMPLETED_TRIPS:
      return {
        ...state,
        completed: action.completed_trips,
        total_trips_completed: action.total_trips_completed,
        isLoadInner: true,
      };
    case accountTripConstants.GET_QUOTE:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
        isLoadInner: false,
      };
    case accountTripConstants.GET_QUOTE_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        save_quotation: action.save_quotation,
      };
    case accountTripConstants.GET_QUOTE_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
        errData: action.errData,
      };
    case accountTripConstants.FLUSH_QUOTATION:
      return {
        ...state,
        isLoad: false,
        message: null,
        save_quotation: {},
        errData: {},
      };
    case accountTripConstants.SAVE_CHARGES_LOAD:
      return {
        ...state,
        isLoadSave: true,
        loadErr: null,
        isLoadInner: false,
      };
    case accountTripConstants.SAVE_CHARGES_SUCCESS:
      return {
        ...state,
        isLoadSave: false,
        loadErr: null,
        message: action.message,
        savedQuotation: action.savedQuotation,
      };
    case accountTripConstants.SAVE_CHARGES_FAIL:
      return {
        ...state,
        isLoadSave: false,
        loadErr: action.error,
        message: null,
      };
    case accountTripConstants.SAVE_FILTERS_QUOTATION:
      return {
        ...state,
        saveFilterQuotation: action.saveFilterQuotation,
      };
    case accountTripConstants.GET_FILTERS_QUOTATION:
      return {
        ...state,
        getFilterQuotation: action.getFilterQuotation,
      };

    case accountTripConstants.GET_CHARGES_LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: null,
        isLoadInner: false,
      };
    case accountTripConstants.GET_CHARGES_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        data: action.data,
        isLoadInner: true,
        total_charges: action.total_charges,
      };
    case accountTripConstants.GET_CHARGES_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
      };
    case accountTripConstants.SAVE_FILTERS:
      return {
        ...state,
        saveFilters: action.saveFilters,
      };
    case accountTripConstants.GET_FILTERS:
      return {
        ...state,
        getFilters: action.getFilters,
      };
    case accountTripConstants.UPDATE_QUOTATION_LOAD:
      return {
        ...state,
        isLoadUpdate: true,
        loadErr: null,
        isLoadInner: false,
      };
    case accountTripConstants.UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        isLoadUpdate: false,
        loadErr: null,
        isLoadInner: true,
      };
    case accountTripConstants.UPDATE_QUOTATION_FAIL:
      return {
        ...state,
        isLoadUpdate: false,
        loadErr: action.error,
      };
    // Default
    default:
      return state;
  }
}
