export const resetPasswordConstants = {
  LOAD: 'resetPassword/LOAD',
  LOAD_SUCCESS: 'resetPassword/LOAD_SUCCESS',
  LOAD_FAIL: 'resetPassword/LOAD_FAIL',
  FLUSH: 'resetPassword/FLUSH',
  FORGOT_PASSWORD_LOAD: 'resetPassword/FORGOT_PASSWORD_LOAD',
  FORGOT_PASSWORD_SUCCESS: 'resetPassword/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'resetPassword/FORGOT_PASSWORD_FAIL',
  VERIFY_TOKEN_LOAD: 'resetPassword/VERIFY_TOKEN_LOAD',
  VERIFY_TOKEN_SUCCESS: 'resetPassword/VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAIL: 'resetPassword/VERIFY_TOKEN_FAIL',
  RESET_PASSWORD_LOAD: 'resetPassword/RESET_PASSWORD_LOAD',
  RESET_PASSWORD_SUCCESS: 'resetPassword/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'resetPassword/RESET_PASSWORD_FAIL',
  VERIFY_OTP_LOAD: 'resetPassword/VERIFY_OTP_LOAD',
  VERIFY_OTP_SUCCESS: 'resetPassword/VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAIL: 'resetPassword/VERIFY_OTP_FAIL',
};
