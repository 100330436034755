export const billingConstants = {
  LOAD: 'billing/LOAD',
  LOAD_SUCCESS: 'billing/LOAD_SUCCESS',
  LOAD_FAIL: 'billing/LOAD_FAIL',
  FLUSH: 'billing/FLUSH',
  ALL_INVOICING: 'billing/ALL_INVOICING',
  FILTER_INVOICING: 'billing/FILTER_INVOICING',
  FLUSH_FILTER_INVOICING: 'billing/FLUSH_FILTER_INVOICING',
  ALL_CORPORATE_ACCOUNT: 'billing/ALL_CORPORATE_ACCOUNT',
  CREATE_INVOICE: 'billing/CREATE_INVOICE',
  BILLED_INVOICE: 'billing/BILLED_INVOICE',
  FINALIZED_INVOICE: 'billing/FINALIZED_INVOICE',
  VALIDATE_INVOICE: 'billing/VALIDATE_INVOICE',
  SENT_INVOICE: 'billing/SENT_INVOICE',
  LOCKED_INVOICE: 'billing/LOCKED_INVOICE',
  PART_PAID_INVOICE: 'billing/PART_PAID_INVOICE',
  FULLY_PAID_INVOICE: 'billing/FULLY_PAID_INVOICE',
  REFUNDED_INVOICE: 'billing/REFUNDED_INVOICE',
  UN_INVOICE: 'billing/UN_INVOICE',
  DISPUTED_INVOICE: 'billing/DISPUTED_INVOICE',
  DISPUTED_ACCEPTED_INVOICE: 'billing/DISPUTED_ACCEPTED_INVOICE',
  CANCELLED_INVOICE: 'billing/CANCELLED_INVOICE',

  TRIP_DETAILS_LOAD: 'billing/TRIP_DETAILS_LOAD',
  TRIP_DETAILS_SUCCESS: 'billing/TRIP_DETAILS_SUCCESS',
  TRIP_DETAILS_FAIL: 'billing/TRIP_DETAILS_FAIL',

  ALL_COMPLETED_INVOICING_LOAD: 'billing/ALL_COMPLETED_INVOICING_LOAD',
  ALL_COMPLETED_INVOICING_LOAD_SUCCESS:
    'billing/ALL_COMPLETED_INVOICING_LOAD_SUCCESS',
  ALL_COMPLETED_INVOICING_LOAD_FAIL:
    'billing/ALL_COMPLETED_INVOICING_LOAD_FAIL',
  ALL_COMPLETED_INVOICING: 'billing/ALL_COMPLETED_INVOICING',

  All_INVOICE_FLUSH: 'billing/All_INVOICE_FLUSH',
  PARTIAL_INVOICE: 'billing/PARTIAL_INVOICE',
  PARTIAL_INVOICE_LOAD: 'billing/PARTIAL_INVOICE_LOAD',
  PARTIAL_INVOICE_LOAD_SUCCESS: 'billing/PARTIAL_INVOICE_LOAD_SUCCESS',
  PARTIAL_INVOICE_LOAD_FAIL: 'billing/PARTIAL_INVOICE_LOAD_FAIL',

  SAVE_INVOICE_STATUS: 'billing/SAVE_INVOICE_STATUS',
  SAVE_INVOICE_STATUS_LOAD: 'billing/SAVE_INVOICE_STATUS_LOAD',
  SAVE_INVOICE_STATUS_LOAD_SUCCESS: 'billing/SAVE_INVOICE_STATUS_LOAD_SUCCESS',
  SAVE_INVOICE_STATUS_LOAD_FAIL: 'billing/SAVE_INVOICE_STATUS_LOAD_FAIL',
  DELETE_INVOICE_LOAD_FAIL: 'billing/DELETE_INVOICE_LOAD_FAIL',
  DELETE_INVOICE_LOAD: 'billing/DELETE_INVOICE_LOAD',
  DELETE_INVOICE_LOAD_SUCCESS: 'billing/DELETE_INVOICE_LOAD_SUCCESS',
  FLUSH_INVOICE_STATUS: 'billing/FLUSH_INVOICE_STATUS',

  GET_TRIPLOG_LOAD: 'billing/GET_TRIPLOG_LOAD',
  GET_TRIPLOG_SUCCESS: 'billing/GET_TRIPLOG_SUCCESS',
  GET_TRIPLOG_FAIL: 'billing/GET_TRIPLOG_FAIL',
  TRIP_LOG_FLUSH: 'billing/TRIP_LOG_FLUSH',

  PAYMENT_LOAD: 'billing/PAYMENT_LOAD',
  PAYMENT_SUCCESS: 'billing/PAYMENT_SUCCESS',
  PAYMENT_FAIL: 'billing/PAYMENT_FAIL',
  SAVE_PAYMENT_METHODS: 'billing/SAVE_PAYMENT_METHODS',
  PAYMENT_LIST_SUCCESS: 'billing/PAYMENT_LIST_SUCCESS',

  AUDIT_DETAILS_LOAD: 'billing/AUDIT_LOAD',
  AUDIT_DETAILS_LOAD_FAIL: 'billing/AUDIT_LOAD_FAILED',
  AUDIT_DETAILS_LOAD_SUCCESS: 'billing/AUDIT_LOAD_SUCCESS',
  AUDIT_DETAILS_SUCCESS: 'billing/AUDIT_SUCCESS',

  GET_STATE: 'billing/GET_STATE',
  SAVE_STATE: 'billing/SAVE_STATE',

  INVOICE_AUDIT_LOAD: 'billing/INVOICE_AUDIT_LOAD',
  INVOICE_AUDIT_SUCCESS: 'billing/INVOICE_AUDIT_SUCCESS',
  INVOICE_AUDIT_FAIL: 'billing/INVOICE_AUDIT_FAIL',

  VERIFY_INVOICE_LOAD: 'billing/VERIFY_INVOICE_LOAD',
  VERIFY_INVOICE_SUCCESS: 'billing/VERIFY_INVOICE_SUCCESS',
  VERIFY_INVOICE_FAIL: 'billing/VERIFY_INVOICE_FAIL',
};
export const billingExportConstants = {
  Invoicing: 'invoicing',
  CompletedInvoicing: 'completed-trip',
};
