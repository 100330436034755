export const userConstants = {
  LOAD: 'user/LOAD',
  LOAD_SUCCESS: 'user/LOAD_SUCCESS',
  LOAD_FAIL: 'user/LOAD_FAIL',
  FLUSH: 'user/FLUSH',
  SAVE_USER: 'user/SAVE_USER',
  ROLES: 'user/ROLES',
  ALL_USER: 'user/ALL_USER',
  SUCCESS: 'user/SUCCESS',
  FLUSH_MESSAGES: 'user/FLUSH_MESSAGES',
  COST_CENTER: 'user/COST_CENTER',
  GET_USER_LOAD: 'user/GET_USER_LOAD',
  GET_USER_LOAD_SUCCESS: 'user/GET_USER_LOAD_SUCCESS',
  GET_USER_LOAD_FAIL: 'user/GET_USER_LOAD_FAIL',
  UPDATE_PASS_LOAD: 'user/UPDATE_PASS_LOAD',
  UPDATE_PASS_SUCCESS: 'user/UPDATE_PASS_SUCCESS',
  UPDATE_PASS_FAIL: 'user/UPDATE_PASS_FAIL',
  CLEAR_MESSAGE: 'user/CLEAR_MESSAGE',
};
