export const authConstants = {
  LOAD: 'auth/LOAD',
  LOAD_SUCCESS: 'auth/LOAD_SUCCESS',
  LOAD_FAIL: 'auth/LOAD_FAIL',
  FLUSH: 'auth/FLUSH',
  ASSIGN_USER: 'auth/ASSIGN_USER',
  SESSION_EXPIRED: 'auth/SESSION_EXPIRED',
  CLEAR_MESSAGE: 'auth/CLEAR_MESSAGE',
  MFA_SETTING_LOAD: 'auth/MFA_SETTING_LOAD',
  MFA_SETTING_SUCCESS: 'auth/MFA_SETTING_SUCCESS',
  MFA_SETTING_FAIL: 'auth/MFA_SETTING_FAIL',
  MFA_VERIFY_LOAD: 'auth/MFA_VERIFY_LOAD',
  MFA_VERIFY_SUCCESS: 'auth/MFA_VERIFY_SUCCESS',
  MFA_VERIFY_FAIL: 'auth/MFA_VERIFY_FAIL',
  FLUSH_TIMER: 'auth/FLUSH_TIMER',
};
