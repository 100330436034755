export const corporateConstants = {
  LOAD: 'corporateConstants/LOAD',
  LOAD_SUCCESS: 'corporateConstants/LOAD_SUCCESS',
  LOAD_FAIL: 'corporateConstants/LOAD_FAIL',
  FLUSH: 'corporateConstants/FLUSH',
  FLUSH_ERROR: 'corporateConstants/FLUSH_ERROR',
  ALL_CORPORATE_ACCOUNTS: 'corporateConstants/ALL_CORPORATE_ACCOUNTS',
  SAVE_CORPORATE_ACCOUNTS: 'corporateConstants/SAVE_CORPORATE_ACCOUNTS',
  DELETE_CORPORATE_ACCOUNTS: 'corporateConstants/DELETE_CORPORATE_ACCOUNTS',
  GET_INVOICE_LOAD: 'corporateConstants/GET_INVOICE_LOAD',
  GET_INVOICE_SUCCESS: 'corporateConstants/GET_INVOICE_SUCCESS',
  GET_INVOICE_FAIL: 'corporateConstants/GET_INVOICE_FAIL',
  INVOICE_FLUSH: 'corporateConstants/INVOICE_FLUSH',
  UPDATE_PRICE_LOAD: 'corporateConstants/UPDATE_PRICE_LOAD',
  UPDATE_PRICE_SUCCESS: 'corporateConstants/UPDATE_PRICE_SUCCESS',
  UPDATE_PRICE_FAIL: 'corporateConstants/UPDATE_PRICE_FAIL',
  GET_VERSIONS_LOAD: 'corporateConstants/GET_VERSIONS_LOAD',
  GET_VERSIONS_SUCCESS: 'corporateConstants/GET_VERSIONS_SUCCESS',
  GET_VERSIONS_FAIL: 'corporateConstants/GET_VERSIONS_FAIL',
  UPDATE_VERSION_LOAD: 'corporateConstants/UPDATE_VERSION_LOAD',
  UPDATE_VERSION_SUCCESS: 'corporateConstants/UPDATE_VERSION_SUCCESS',
  UPDATE_VERSION_FAIL: 'corporateConstants/UPDATE_VERSION_FAIL',
  FLUSH_MESSAGE: 'corporateConstants/FLUSH_MESSAGE',
};
