export const logsConstants = {
  LOAD: "logs/LOAD",
  LOAD_SUCCESS: "logs/LOAD_SUCCESS",
  LOAD_FAIL: "logs/LOAD_FAIL",
  FLUSH: "logs/FLUSH",
  APPLICATION_LOGS: "logs/APPLICATION_LOGS",
  AUDIT_LOGS: "logs/AUDIT_LOGS",
  LOGS_AUDIT: "logs/LOGS_AUDIT",
  SUCCESS: "logs/SUCCESS",
  FLUSH_MESSAGES: "logs/FLUSH_MESSAGES",
  FLUSH_APPLICATION_LOGS: "logs/FLUSH_APPLICATION_LOGS",
};
