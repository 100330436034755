export const messages = {
  DEFAULT_ERROR_MESSAGE: "Oops!! something went wrong, please try again",
  ERROR_MESSAGE: "Oops!! something went wrong, please login again",
  VALIDATIONS: {
    REQUIRED: "* Required",
    INVALID_EMAIL_ADDRESS: "Please provide a valid email address",
    EMAIL_ALREADY_EXISTS: "Email address is already taken",
    EMAIL_REQUIRED_FOR_FORGOT_PASSWORD: "Please enter your registered email address",
    INVALID_URL: "Please enter a valid url",
    INVALID_PHONE_NUMBER: "Please enter a valid phone number",
    INVALID_MOBILE_NUMBER: "Please enter a valid mobile number",
    STRICT_VALID_INTEGER: "Must be an integer",
    INVALID_FILE_NAME: "Please select a valid file",
    INVALID_PASSWORD_FORMAT: "Please enter your password as per the password requirements",
  },
};
